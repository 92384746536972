import Twemoji from "react-twemoji";

import { ScopeType } from "sockapi/scope-types";
import { SockAPIScopeState } from "sockapi/scope-state";
import { TeamNameDisplayData } from "sockapi/scope-specs/team-list-spec";
import { getTeamCollectibleIcons } from "sockapi/gacha/pulls";

import {
  useTeamId,
  useShouldDisplayAdmin,
} from "stores/ServerInteractionStore";
import {
  useGlobalScopeState,
  useGlobalScopeStatePosthuntable,
  useScopeStateResolvable,
  useTeamScopeState,
} from "stores/ScopeStateStore";
import { DjangoLink, RoutedLink } from "components/Link";
import { Spinner } from "components/Loading";
import Tooltip from "components/Tooltip";
import { clsObj } from "client-util";
import settings from "settings";
import "./TeamDisplayNameDisplay.css";

const teamScopeStateToTeamNameDisplayData = (
  teamId: string,
  { displayName }: SockAPIScopeState<ScopeType.TEAM>,
  teamCollectiblesScopeState: SockAPIScopeState<ScopeType.TEAM_COLLECTIBLES>,
  huntEndTime: number | null
): TeamNameDisplayData => {
  const collectibleIcons = getTeamCollectibleIcons(
    teamCollectiblesScopeState,
    huntEndTime
  );
  return {
    teamId,
    displayName,
    collectibleIcons: collectibleIcons === "" ? undefined : collectibleIcons,
  };
};

type TeamDisplayNameDisplayRenderProps = {
  invalid?: boolean;
  noLink?: boolean;
  hideAdminLinks?: boolean;
  inline?: boolean;
};

type TeamDisplayNameDisplayProps = TeamDisplayNameDisplayRenderProps & {
  teamData: TeamNameDisplayData;
};

export const makeTeamPagePath = (displayName: string): string =>
  `/team/${encodeURIComponent(encodeURIComponent(displayName))}`;

export function TeamDisplayNameDisplayPreloaded(
  props: TeamDisplayNameDisplayProps
) {
  const {
    teamData,
    invalid = false,
    noLink,
    hideAdminLinks: hideAdminLinksOpt = false,
    inline = false,
  } = props;
  const { teamId, displayName, collectibleIcons } = teamData;
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const showAdminLinks = !hideAdminLinksOpt && shouldDisplayAdmin;
  const huntAdminScopeState = useGlobalScopeState(
    ScopeType.HUNT_ADMIN,
    showAdminLinks
  );

  if (showAdminLinks && huntAdminScopeState === null)
    return <Spinner inline={true} />;
  const userId = huntAdminScopeState?.teamAdminData[teamId]?.userId ?? null;

  const twemojiDisplayName = (
    <span className="team-display-name">
      {(collectibleIcons ?? "").length > 0 ? (
        <span className="team-name-collectibles">
          <Twemoji tag="span">{collectibleIcons}</Twemoji>&nbsp;
        </span>
      ) : null}
      <Twemoji tag="span">{displayName}</Twemoji>
    </span>
  );
  const modifiedDisplayName = invalid ? (
    <s>{twemojiDisplayName}</s>
  ) : (
    twemojiDisplayName
  );
  const className = clsObj({
    "team-name-link": true,
    "team-name-link-inline": inline,
    "team-name-invalid": invalid,
  });
  return (
    <>
      {showAdminLinks ? (
        <span className="button-squish-zone secondary team-name-collectibles">
          {
            // If impersonating, we need to stop impersonating
            // first, otherwise the impersonate button doesn't work.
            userId !== null && !settings.isImpersonate ? (
              <DjangoLink href={`impersonate/${userId}`} button={true}>
                <Tooltip text="Impersonate">
                  <Twemoji tag="span">🕵️</Twemoji>
                </Tooltip>
              </DjangoLink>
            ) : null
          }
          <RoutedLink path={`/sock-admin/${teamId}`} button={true}>
            <Tooltip text="Sock Admin">
              <Twemoji tag="span">🧦️</Twemoji>
            </Tooltip>
          </RoutedLink>
          &nbsp;
        </span>
      ) : null}
      {noLink ? (
        <span className={className}>{modifiedDisplayName}</span>
      ) : (
        <RoutedLink className={className} path={makeTeamPagePath(displayName)}>
          {modifiedDisplayName}
        </RoutedLink>
      )}
    </>
  );
}

/** Team display name display for a team we have full access to. */
function AccessibleTeamDisplayNameDisplay(
  props: TeamDisplayNameDisplayRenderProps & { teamId: string }
) {
  const { teamId, ...renderProps } = props;
  const teamScopeState = useTeamScopeState(teamId);
  const teamCollectiblesScopeState = useScopeStateResolvable(
    ScopeType.TEAM_COLLECTIBLES,
    teamId === null ? null : { teamId }
  );

  if (teamScopeState === null || teamCollectiblesScopeState === null)
    return <Spinner inline={true} />;

  return (
    <TeamDisplayNameDisplayPreloaded
      teamData={teamScopeStateToTeamNameDisplayData(
        teamId,
        teamScopeState,
        teamCollectiblesScopeState,
        // Show all collectibles for our own team even after hunt ends,
        // for posthunt progression.
        null
      )}
      {...renderProps}
    />
  );
}

/** Team display name display for a team we don't have full access to. */
function InaccessibleTeamDisplayNameDisplay(
  props: TeamDisplayNameDisplayRenderProps & { teamId: string }
) {
  const { teamId, ...renderProps } = props;
  const teamData_ = useGlobalScopeState(ScopeType.TEAM_LIST)?.[teamId] ?? null;
  const posthuntTeamData = useGlobalScopeStatePosthuntable(
    ScopeType.TEAM_LIST,
    true,
    true
  );
  const teamData =
    teamData_ ??
    (posthuntTeamData?.success ? posthuntTeamData?.state[teamId] : null);

  if (teamData === null) return <Spinner inline={true} />;

  return (
    <TeamDisplayNameDisplayPreloaded teamData={teamData} {...renderProps} />
  );
}

function TeamDisplayNameDisplay(
  props: TeamDisplayNameDisplayRenderProps & { teamId?: string }
) {
  const { teamId: teamIdOpt, ...renderProps } = props;
  const ownTeamId = useTeamId();
  const teamId = teamIdOpt ?? ownTeamId;

  if (ownTeamId === null || teamId === null) return <Spinner inline={true} />;

  return teamId === ownTeamId ? (
    <AccessibleTeamDisplayNameDisplay teamId={teamId} {...renderProps} />
  ) : (
    <InaccessibleTeamDisplayNameDisplay teamId={teamId} {...renderProps} />
  );
}

export default TeamDisplayNameDisplay;
