import { useEffect, useState } from "react";
import { create } from "zustand";
import { useMatch, useParams, useLocation } from "react-router-dom";

import Globals from "Globals";
import { useEvent } from "client-util";
import { useShouldDisplayAdmin } from "stores/ServerInteractionStore";
import { useTeamScopeState } from "stores/ScopeStateStore";
import { useIsAdminEnabledStore } from "stores/UserPreferencesStore";

export enum AdminQueueFilterType {
  HINT = "hint",
  PUZZLE_105 = "puzzle_105",
  PUZZLE_139 = "puzzle_139",
  PUZZLE_144 = "puzzle_144",
}

/** Command to trigger a router redirect. */
type RedirectCommand = {
  path: string;
  replace?: boolean;
  isManual?: true;
};

interface NavStoreState {
  /**
   * Whether the page has a React Router component. This allows us to
   * hook up navbar links to React Router without making them part of
   * the main React component.
   */
  hasRouter: boolean;
  setHasRouter: (hasRouter: boolean) => void;

  /**
   * Setting this triggers an in-app redirect, and then resets this state.
   */
  redirectCommand: RedirectCommand | null;
  setRedirectCommand: (redirectCommand: RedirectCommand | null) => void;

  bigBoardTeamFilter: string;
  setBigBoardTeamFilter: (bigBoardTeamFilter: string) => void;
  bigBoardPuzzleFilter: string;
  setBigBoardPuzzleFilter: (bigBoardPuzzleFilter: string) => void;

  adminQueuePageNum: number;
  setAdminQueuePageNum: (adminQueuePageNum: number) => void;

  fishCount: number;
  setFishCount: (fishCount: number) => void;

  adminQueueTypeFilter: { [filterType in AdminQueueFilterType]: boolean };
  setAdminQueueTypeFilter: (adminQueueTypeFilter: {
    [filterType in AdminQueueFilterType]: boolean;
  }) => void;
  adminQueueTeamNameFilter: string;
  setAdminQueueTeamNameFilter: (adminQueueTeamNameFilter: string) => void;

  isAdminQueuePosthuntView: boolean;
  setIsAdminQueuePosthuntView: (isAdminQueuePosthuntView: boolean) => void;
  adminQueueShowPublicPuzzles: boolean;
  setAdminQueueShowPublicPuzzles: (
    adminQueueShowPublicPuzzles: boolean
  ) => void;
}

export const useNavStore = create<NavStoreState>((set) => ({
  hasRouter: false,
  setHasRouter: (hasRouter) => set({ hasRouter }),

  redirectCommand: null,
  setRedirectCommand: (redirectCommand) => set({ redirectCommand }),

  bigBoardTeamFilter: "",
  setBigBoardTeamFilter: (bigBoardTeamFilter) => set({ bigBoardTeamFilter }),
  bigBoardPuzzleFilter: "",
  setBigBoardPuzzleFilter: (bigBoardPuzzleFilter) =>
    set({ bigBoardPuzzleFilter }),

  adminQueuePageNum: 0,
  setAdminQueuePageNum: (adminQueuePageNum) => set({ adminQueuePageNum }),

  fishCount: 0,
  setFishCount: (fishCount) => set({ fishCount }),

  adminQueueTypeFilter: {
    [AdminQueueFilterType.HINT]: true,
    [AdminQueueFilterType.PUZZLE_105]: true,
    [AdminQueueFilterType.PUZZLE_139]: true,
    [AdminQueueFilterType.PUZZLE_144]: true,
  },
  setAdminQueueTypeFilter: (adminQueueTypeFilter) =>
    set({ adminQueueTypeFilter }),

  adminQueueTeamNameFilter: "",
  setAdminQueueTeamNameFilter: (adminQueueTeamNameFilter) =>
    set({ adminQueueTeamNameFilter }),

  isAdminQueuePosthuntView: false,
  setIsAdminQueuePosthuntView: (isAdminQueuePosthuntView) =>
    set({ isAdminQueuePosthuntView }),
  adminQueueShowPublicPuzzles: true,
  setAdminQueueShowPublicPuzzles: (adminQueueShowPublicPuzzles) =>
    set({ adminQueueShowPublicPuzzles }),
}));

export const useSlug = (): string | null => {
  const { slug } = useParams<{
    slug?: string;
  }>();

  if (Globals.slug !== undefined) return Globals.slug;
  if (slug !== undefined) return slug;

  return null;
};

/**
 * Parses the slug.
 * Returns null if any dependency is not ready.
 * Returns null in the slug field if no slug is found.
 * Returns null in the puzName field if the slug does not match
 * any unlocked puzzle.
 */
export const useParsedSlug = (): {
  slug: string | null;
  puzName: string | null;
} | null => {
  const slug = useSlug();
  const teamScopeState = useTeamScopeState();
  if (teamScopeState === null) return null;
  if (slug === null) return { slug: null, puzName: null };
  const puzData = Object.values(teamScopeState.puzzles).find(
    (puzData) => puzData.slug === slug
  );
  return { slug, puzName: puzData?.puzName ?? null };
};

export const usePuzName = (): string | null => {
  const parsedSlug = useParsedSlug();
  return parsedSlug?.puzName ?? null;
};

const useIsWindowNarrow = () => {
  // magic number 800...
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < 800);
  const onResize = useEvent(() => setIsNarrow(window.innerWidth < 800));
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);
  return isNarrow;
};

export const useInPuzzlePage = () => {
  const inCamp = useMatch("/camp") !== null;
  const inPuzzleNav = useMatch("/puzzles") !== null;
  const inPuzzle = useMatch("/puzzle/:slug") !== null;
  const inSolution = useMatch("/solution/:slug") !== null;
  const inHints = useMatch("/hints/:slug") !== null;
  const inStats = useMatch("/stats/:slug") !== null;
  return {
    inCamp,
    inPuzzleNav,
    inPuzzle,
    inSolution,
    inHints,
    inStats,
    inPuzzlePage: inPuzzleNav || inPuzzle || inSolution || inHints || inStats,
  };
};

export const useCampLayout = () => {
  const { inPuzzlePage, inPuzzleNav } = useInPuzzlePage();
  const teamScopeState = useTeamScopeState();
  const puzzlesArr =
    teamScopeState !== null ? Object.values(teamScopeState.puzzles) : null;
  const onlyOpenPuzzle =
    puzzlesArr !== null && puzzlesArr.length === 1 ? puzzlesArr[0] : null;

  // If set, then all pages should redirect to this puzzle.
  const huntStartPuzNameOverride =
    onlyOpenPuzzle !== null && onlyOpenPuzzle.solveTime === undefined
      ? onlyOpenPuzzle.slug
      : null;
  const isBeforeFirstWish = onlyOpenPuzzle !== null;
  // Don't show the sidebar until we're past the first wish.
  const isSinglePane = useIsWindowNarrow() || isBeforeFirstWish;

  // Return null if dependencies are not fulfilled.
  if (teamScopeState === null) return null;

  return {
    huntStartPuzNameOverride,
    isBeforeFirstWish,
    isSinglePane,
    showSidebar: isSinglePane ? inPuzzleNav : inPuzzlePage,
  };
};

export const useRedirectIfNonAdmin = () => {
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const isAdminEnabled = useIsAdminEnabledStore(
    (state) => state.isAdminEnabled
  );
  const setRedirectCommand = useNavStore((state) => state.setRedirectCommand);
  const currPath = useLocation().pathname;

  useEffect(() => {
    if (isAdminEnabled) return;
    if (shouldDisplayAdmin) return;
    if (currPath === "/camp") return;
    toastr.error("Permission denied.");
    setRedirectCommand({ path: "/camp", replace: true });
  }, [isAdminEnabled, shouldDisplayAdmin, setRedirectCommand, currPath]);
};
